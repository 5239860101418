/* 存放消息中心路由 */
import { DEFAULT_LAYOUT } from '../base';
// import DEFAULT_LAYOUT from '@/layout/default-layout.vue'
import { AppRouteRecordRaw } from '../types';

const C_APPLYCENTER: AppRouteRecordRaw = {
  path: 'applyCenter',
  name: 'C_ApplyCenter',
  component: () => import('@/views/Client/ApplyCenter/index.vue'),
  meta: {
    locale: '申请中心',
    requiresAuth: true,
    icon: 'icon-home',
    order: 3,
    hideInMenu: false,
    roles: ['*']
  }
};

export default C_APPLYCENTER;
