import type { RouteRecordNormalized } from 'vue-router';

const clientModules = import.meta.glob('./client/*.ts', { eager: true });
const managementModules = import.meta.glob('./management/*.ts', {
  eager: true
});

function formatModules(_modules: any, result: RouteRecordNormalized[]) {
  Object.keys(_modules).forEach((key) => {
    const defaultModule = _modules[key].default;
    if (!defaultModule) return;
    const moduleList = Array.isArray(defaultModule)
      ? [...defaultModule]
      : [defaultModule];
    result.push(...moduleList);
  });
  return result;
}

export const appClientRoutes: RouteRecordNormalized[] = formatModules(
  clientModules,
  []
);

export const appManagementRoutes: RouteRecordNormalized[] = formatModules(
  managementModules,
  []
);
