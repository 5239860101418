/* 存放我的审批路由 */
import { DEFAULT_LAYOUT } from '../base';
// import DEFAULT_LAYOUT from '@/layout/default-layout.vue'
import { AppRouteRecordRaw } from '../types';

const C_EXAMINE: AppRouteRecordRaw[] = [
  {
    path: 'examine',
    name: 'C_Examine',
    component: () => import('@/views/Client/Examine/index.vue'),
    meta: {
      locale: '我的审批',
      requiresAuth: true,
      icon: 'icon-home',
      order: 4,
      hideInMenu: false,
      roles: ['*']
    }
  },
  {
    path: 'record',
    name: 'Record',
    component: () => import('@/views/Client/Record/index.vue'),
    meta: {
      locale: '我的档案',
      requiresAuth: true,
      icon: 'icon-home',
      order: 3,
      hideInMenu: false,
      roles: ['*']
    }
  }
];

export default C_EXAMINE;
