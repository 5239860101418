/* 存放绩效管理路由 */
import { DEFAULT_LAYOUT } from '../base';
// import DEFAULT_LAYOUT from '@/layout/default-layout.vue'
import { AppRouteRecordRaw } from '../types';

const C_CHECKATTENDANCE: AppRouteRecordRaw = {
  path: 'performanceManagement',
  name: 'C_PerformanceManagement',
  meta: {
    locale: '我的绩效',
    requiresAuth: true,
    icon: 'icon-home',
    order: 8,
    hideInMenu: false,
    roles: ['user']
  },
  redirect: {
    name: 'CreateAssessment'
  },
  children: [
    {
      path: 'CreateAssessment',
      name: 'CreateAssessment',
      component: () =>
        import(
          '@/views/Management/Home/MyPerformance/CreateAssessment/index.vue'
        ),
      meta: {
        locale: '创建考核方案',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: false
      }
    },
    {
      path: 'myInternshipEva',
      name: 'MyInternshipEva',
      component: () =>
        import('@/views/Management/Home/MyPerformance/InternshipEva/index.vue'),
      meta: {
        locale: '实习评价',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: false
      }
    },
    {
      path: 'myProbationAssessment',
      name: 'MyProbationAssessment',
      component: () =>
        import(
          '@/views/Management/Home/MyPerformance/ProbationAssessment/index.vue'
        ),
      meta: {
        locale: '我的绩效',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: false
      }
    },
    {
      path: 'myProbationAssessment/detail',
      name: 'MyProbationAssessmentDetail',
      component: () =>
        import(
          '@/views/Management/Home/MyPerformance/ProbationAssessment/Detail/index.vue'
        ),
      meta: {
        locale: '试用期考核方案详情',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: true
      }
    },
    {
      path: 'myProbationAssessment/list',
      name: 'MyProbationAssessmentList',
      component: () =>
        import(
          '@/views/Management/Home/MyPerformance/ProbationAssessment/ExamineList/index.vue'
        ),
      meta: {
        locale: '试用期考核',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: true
      }
    },
    {
      path: 'myPerformanceAssessment',
      name: 'MyPerformanceAssessment',
      component: () =>
        import(
          '@/views/Management/Home/MyPerformance/PerformanceAssessment/index.vue'
        ),
      meta: {
        locale: '绩效考核',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: false
      }
    },
    {
      path: 'myOtherAssessment',
      name: 'MyOtherAssessment',
      component: () =>
        import(
          '@/views/Management/Home/MyPerformance/OtherAssessment/index.vue'
        ),
      meta: {
        locale: '其他考核',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: false
      }
    },
    {
      path: 'myPerformanceEva',
      name: 'MyPerformanceEva',
      component: () =>
        import(
          '@/views/Management/Home/MyPerformance/PerformanceEva/index.vue'
        ),
      meta: {
        locale: '绩效评价任务',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: false
      }
    },
    {
      path: 'ExamineAppeal',
      name: 'examineAppeal',
      component: () =>
        import('@/views/Management/Home/MyPerformance/ExamineAppeal/index.vue'),
      meta: {
        locale: '申诉受理',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: false
      }
    },
    {
      path: 'ChangeHandling',
      name: 'changeHandling',
      component: () =>
        import(
          '@/views/Management/Home/MyPerformance/ChangeHandling/index.vue'
        ),
      meta: {
        locale: '变更处理',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: false
      }
    },
    {
      path: 'AssessmentResults',
      name: 'assessmentResults',
      component: () =>
        import(
          '@/views/Management/Home/MyPerformance/AssessmentResults/index.vue'
        ),
      meta: {
        locale: '考核结果',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: false
      }
    }
  ]
  // children: [
  // {
  //   path: 'myPerformance',
  //   name: 'C_MyPerformance',
  //   component: () =>
  //     import('@/views/Client/PerformanceManagement/MyPerformance/index.vue'),
  //   meta: {
  //     locale: '我的绩效',
  //     requiresAuth: true,
  //     icon: 'icon-home',
  //     hideInMenu: false,
  //     roles: ['user']
  //   }
  // },
  // {
  //   path: 'targetManagement',
  //   name: 'C_TargetManagement',
  //   component: () =>
  //     import(
  //       '@/views/Client/PerformanceManagement/TargetManagement/index.vue'
  //     ),
  //   meta: {
  //     locale: '目标管理',
  //     requiresAuth: true,
  //     icon: 'icon-home',
  //     hideInMenu: false,
  //     roles: ['user']
  //   }
  // },
  // {
  //   path: 'subManagement',
  //   name: 'C_SubManagement',
  //   component: () =>
  //     import('@/views/Client/PerformanceManagement/SubManagement/index.vue'),
  //   meta: {
  //     locale: '下属管理',
  //     requiresAuth: true,
  //     icon: 'icon-home',
  //     hideInMenu: false,
  //     roles: ['user']
  //   }
  // },
  // {
  //   path: 'myProbation',
  //   name: 'C_MyProbation',
  //   component: () =>
  //     import('@/views/Client/PerformanceManagement/MyProbation/index.vue'),
  //   meta: {
  //     locale: '我的试用期考核',
  //     requiresAuth: true,
  //     icon: 'icon-home',
  //     hideInMenu: false,
  //     roles: ['user']
  //   }
  // },
  // {
  //   path: 'evaluationTask',
  //   name: 'C_EvaluationTask',
  //   component: () =>
  //     import('@/views/Client/PerformanceManagement/EvaluationTask/index.vue'),
  //   meta: {
  //     locale: '绩效评价任务',
  //     requiresAuth: true,
  //     icon: 'icon-home',
  //     hideInMenu: false,
  //     roles: ['user']
  //   }
  // },
  // {
  //   path: 'evaluationResult',
  //   name: 'C_EvaluationResult',
  //   component: () =>
  //     import(
  //       '@/views/Client/PerformanceManagement/EvaluationResult/index.vue'
  //     ),
  //   meta: {
  //     locale: '绩效评价结果调整',
  //     requiresAuth: true,
  //     icon: 'icon-home',
  //     hideInMenu: false,
  //     roles: ['user']
  //   }
  // }
  // ]
};

export default C_CHECKATTENDANCE;
