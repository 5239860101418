/* 存放薪酬管理路由 */
import { DEFAULT_LAYOUT } from '../base';
// import DEFAULT_LAYOUT from '@/layout/default-layout.vue'
import { AppRouteRecordRaw } from '../types';

const C_SALARYMANAGEMENT: AppRouteRecordRaw = {
  path: 'salaryManagement',
  name: 'C_SalaryManagement',
  meta: {
    locale: '薪酬管理',
    requiresAuth: true,
    icon: 'icon-home',
    order: 9,
    hideInMenu: false,
    roles: ['user']
  },
  redirect: {
    name: 'C_MyContract'
  },
  children: [
    {
      path: 'mySalary',
      name: 'C_MySalaryt',
      component: () =>
        import('@/views/Client/SalaryManagement/MySalary/index.vue'),
      meta: {
        locale: '我的薪酬',
        requiresAuth: true,
        icon: 'icon-home',
        hideInMenu: false,
        roles: ['user']
      }
    },
    {
      path: 'salaryExamine',
      name: 'C_SalaryExamine',
      component: () =>
        import('@/views/Client/SalaryManagement/SalaryExamine/index.vue'),
      meta: {
        locale: '薪酬审批',
        requiresAuth: true,
        icon: 'icon-home',
        hideInMenu: false,
        roles: ['user']
      }
    }
  ]
};

export default C_SALARYMANAGEMENT;
