/* 存放部门管理路由 */
import { DEFAULT_LAYOUT } from '../base';
// import DEFAULT_LAYOUT from '@/layout/default-layout.vue'
import { AppRouteRecordRaw } from '../types';

const C_DeptManagement: AppRouteRecordRaw = {
  path: 'deptManagement',
  name: 'C_DeptManagement',
  meta: {
    locale: '部门管理',
    requiresAuth: true,
    icon: 'icon-home',
    order: 7,
    hideInMenu: false,
    roles: ['user']
  },
  redirect: {
    name: 'DeptPeopleList'
  },
  children: [
    {
      path: 'deptPeopleList',
      name: 'DeptPeopleList',
      component: () =>
        import(
          '@/views/Management/Home/DeptManagement/DeptPeopleList/index.vue'
        ),
      meta: {
        locale: '部门花名册',
        requiresAuth: true,
        icon: 'icon-home',
        hideInMenu: false,
        roles: ['*']
      }
    },
    {
      path: 'deptApply',
      name: 'DeptApply',
      component: () =>
        import('@/views/Management/Home/DeptManagement/DeptApply/index.vue'),
      meta: {
        locale: '部门申请',
        requiresAuth: true,
        icon: 'icon-home',
        hideInMenu: false,
        roles: ['*']
      }
    },
    {
      path: 'deptAttendance',
      name: 'DeptAttendance',
      component: () =>
        import(
          '@/views/Management/Home/DeptManagement/DeptAttendance/index.vue'
        ),
      meta: {
        locale: '部门考勤',
        requiresAuth: true,
        icon: 'icon-home',
        hideInMenu: false,
        roles: ['*']
      }
    },
    {
      path: 'deptPerformance',
      name: 'DeptPerformance',
      component: () =>
        import(
          '@/views/Management/Home/DeptManagement/DeptPerformance/index.vue'
        ),
      meta: {
        locale: '部门绩效',
        requiresAuth: true,
        icon: 'icon-home',
        hideInMenu: false,
        roles: ['*']
      }
    },
    {
      path: 'deptSalary',
      name: 'DeptSalary',
      component: () =>
        import('@/views/Management/Home/DeptManagement/DeptSalary/index.vue'),
      meta: {
        locale: '部门薪酬',
        requiresAuth: true,
        icon: 'icon-home',
        hideInMenu: false,
        roles: ['*']
      }
    }
  ]
  // children: [
  //   {
  //     path: 'deptPeopleList',
  //     name: 'C_DeptPeopleList',
  //     component: () =>
  //       import('@/views/Client/DeptManagement/DeptPeopleList/index.vue'),
  //     meta: {
  //       locale: '部门人员列表',
  //       requiresAuth: true,
  //       icon: 'icon-home',
  //       hideInMenu: false,
  //       roles: ['user']
  //     }
  //   },
  //   {
  //     path: 'deptApply',
  //     name: 'C_DeptApply',
  //     component: () =>
  //       import('@/views/Client/DeptManagement/DeptApply/index.vue'),
  //     meta: {
  //       locale: '部门申请',
  //       requiresAuth: true,
  //       icon: 'icon-home',
  //       hideInMenu: false,
  //       roles: ['user']
  //     }
  //   },
  //   {
  //     path: 'deptAttendance',
  //     name: 'C_DeptAttendance',
  //     component: () =>
  //       import('@/views/Client/DeptManagement/DeptAttendance/index.vue'),
  //     meta: {
  //       locale: '部门考勤',
  //       requiresAuth: true,
  //       icon: 'icon-home',
  //       hideInMenu: false,
  //       roles: ['user']
  //     }
  //   },
  //   {
  //     path: 'deptContract',
  //     name: 'C_DeptContract',
  //     component: () =>
  //       import('@/views/Client/DeptManagement/DeptContract/index.vue'),
  //     meta: {
  //       locale: '部门劳动合同',
  //       requiresAuth: true,
  //       icon: 'icon-home',
  //       hideInMenu: false,
  //       roles: ['user']
  //     }
  //   },
  //   {
  //     path: 'deptPerformance',
  //     name: 'C_DeptPerformance',
  //     component: () =>
  //       import('@/views/Client/DeptManagement/DeptPerformance/index.vue'),
  //     meta: {
  //       locale: '部门绩效',
  //       requiresAuth: true,
  //       icon: 'icon-home',
  //       hideInMenu: false,
  //       roles: ['user']
  //     }
  //   },
  //   {
  //     path: 'deptProbation',
  //     name: 'C_DeptProbation',
  //     component: () =>
  //       import('@/views/Client/DeptManagement/DeptProbation/index.vue'),
  //     meta: {
  //       locale: '部门试用期考核',
  //       requiresAuth: true,
  //       icon: 'icon-home',
  //       hideInMenu: false,
  //       roles: ['user']
  //     }
  //   }
  // ]
};

export default C_DeptManagement;
