/* 离职管理 */
import { DEFAULT_LAYOUT } from '../base';
// import DEFAULT_LAYOUT from '@/layout/default-layout.vue'
import { AppRouteRecordRaw } from '../types';

const C_WAITDONE: AppRouteRecordRaw = {
  path: '/myEntryManagement',
  name: 'MyEntryManagement',
  meta: {
    locale: '入职管理',
    requiresAuth: true,
    icon: 'icon-jichushezhi',
    roles: ['*'],
    order: 11,
    hideInMenu: false
  },
  redirect: {
    name: 'MyEntryFormalities'
  },
  children: [
    {
      path: 'myEntryFormalities',
      name: 'MyEntryFormalities',
      component: () =>
        import('@/views/Management/Home/EntryManagement/Formalities/index.vue'),
      meta: {
        locale: '入职手续',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['*'],
        hideInMenu: false
      }
    },
    {
      path: 'myEntryHandleTask',
      name: 'MyEntryHandleTask',
      component: () =>
        import('@/views/Management/Home/EntryManagement/HandleTask/index.vue'),
      meta: {
        locale: '接收任务',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['*'],
        hideInMenu: false
      }
    },
    {
      path: 'myHandlerEntryFormalities',
      name: 'MyHandlerEntryFormalities',
      component: () =>
        import(
          '@/views/Management/Home/EntryManagement/HandleTask/Components/Formalities/index.vue'
        ),
      meta: {
        locale: '入职手续（办理人）',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['*'],
        hideInMenu: true
      }
    }
  ]
};

export default C_WAITDONE;
