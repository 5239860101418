/* 存放通讯录路由 */
import { DEFAULT_LAYOUT } from '../base';
// import DEFAULT_LAYOUT from '@/layout/default-layout.vue'
import { AppRouteRecordRaw } from '../types';

const C_ADDRESSBOOK: AppRouteRecordRaw = {
  path: 'addressBook',
  name: 'C_AddressBook',
  component: () => import('@/views/Client/AddressBook/index.vue'),
  meta: {
    locale: '通讯录',
    requiresAuth: true,
    icon: 'icon-home',
    order: 5,
    hideInMenu: false,
    roles: ['*']
  }
};

export default C_ADDRESSBOOK;
