/* 离职管理 */
import { DEFAULT_LAYOUT } from '../base';
// import DEFAULT_LAYOUT from '@/layout/default-layout.vue'
import { AppRouteRecordRaw } from '../types';

const C_WAITDONE: AppRouteRecordRaw = {
  path: '/myInternshipManagement',
  name: 'MyInternshipManagement',
  meta: {
    locale: '实习管理',
    requiresAuth: true,
    icon: 'icon-jichushezhi',
    roles: ['*'],
    order: 12,
    hideInMenu: false
  },
  redirect: {
    name: 'MyInternshipSummary'
  },
  children: [
    {
      path: 'myInternshipSummary',
      name: 'MyInternshipSummary',
      component: () =>
        import(
          '@/views/Management/Home/InternshipManagement/Summary/index.vue'
        ),
      meta: {
        locale: '实习总结',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['*'],
        hideInMenu: false
      }
    },
    {
      path: 'myInternshipEvaluation',
      name: 'MyInternshipEvaluation',
      component: () =>
        import(
          '@/views/Management/Home/InternshipManagement/Evaluation/index.vue'
        ),
      meta: {
        locale: '实习评价',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['*'],
        hideInMenu: false
      }
    }
  ]
};

export default C_WAITDONE;
