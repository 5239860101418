/* 存放我的考勤路由 */
import { DEFAULT_LAYOUT } from '../base';
// import DEFAULT_LAYOUT from '@/layout/default-layout.vue'
import { AppRouteRecordRaw } from '../types';

const C_CHECKATTENDANCE: AppRouteRecordRaw = {
  path: 'checkAttendance',
  name: 'C_CheckAttendance',
  meta: {
    locale: '我的考勤',
    requiresAuth: true,
    icon: 'icon-home',
    order: 6,
    hideInMenu: false,
    roles: ['user']
  },
  redirect: {
    name: 'C_MyAttendance'
  },
  children: [
    {
      path: 'myAttendance',
      name: 'C_MyAttendance',
      component: () =>
        import('@/views/Client/CheckAttendance/MyAttendance/index.vue'),
      meta: {
        locale: '我的考勤',
        requiresAuth: true,
        icon: 'icon-home',
        hideInMenu: false,
        roles: ['user']
      }
    },
    {
      path: 'deptAttendance',
      name: 'C_DeptAttendance',
      component: () =>
        import('@/views/Client/CheckAttendance/DeptAttendance/index.vue'),
      meta: {
        locale: '部门考勤',
        requiresAuth: true,
        icon: 'icon-home',
        hideInMenu: false,
        roles: ['user']
      }
    }
  ]
};

export default C_CHECKATTENDANCE;
