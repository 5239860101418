/* 存放我的待办路由 */
import { DEFAULT_LAYOUT } from '../base';
// import DEFAULT_LAYOUT from '@/layout/default-layout.vue'
import { AppRouteRecordRaw } from '../types';

const C_WAITDONE: AppRouteRecordRaw = {
  path: 'waitDone',
  name: 'C_WaitDone',
  component: () => import('@/views/Client/WaitDone/index.vue'),
  meta: {
    locale: '我的待办',
    requiresAuth: true,
    icon: 'icon-home',
    order: 2,
    hideInMenu: false,
    roles: ['*']
  }
};

export default C_WAITDONE;
