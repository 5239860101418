/* 存放员工工作台路由 */
import { DEFAULT_LAYOUT } from '../base';
// import DEFAULT_LAYOUT from '@/layout/default-layout.vue'
import { AppRouteRecordRaw } from '../types';

const C_CLIENTWORKPLACE: AppRouteRecordRaw = {
  path: 'clientWorkPlace',
  name: 'C_ClientWorkPlace',
  component: () => import('@/views/Client/WorkPlace/index.vue'),
  meta: {
    locale: '工作台',
    requiresAuth: true,
    icon: 'icon-home',
    order: 0,
    hideInMenu: false,
    roles: ['*']
  }
};

export default C_CLIENTWORKPLACE;
