/* 离职管理 */
import { DEFAULT_LAYOUT } from '../base';
// import DEFAULT_LAYOUT from '@/layout/default-layout.vue'
import { AppRouteRecordRaw } from '../types';

const C_WAITDONE: AppRouteRecordRaw = {
  path: '/myLeaveManagement',
  name: 'MyLeaveManagement',
  meta: {
    locale: '离职管理',
    requiresAuth: true,
    icon: 'icon-jichushezhi',
    roles: ['*'],
    order: 10,
    hideInMenu: false
  },
  redirect: {
    name: 'MyLeaveManagement'
  },
  children: [
    {
      path: 'myLeaveWorkHandover',
      name: 'MyLeaveWorkHandover',
      component: () =>
        import(
          '@/views/Management/Home/LeaveManagement/WorkHandover/index.vue'
        ),
      meta: {
        locale: '工作交接',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['*'],
        hideInMenu: false
      }
    },
    {
      path: 'myLeaveFormalities',
      name: 'MyLeaveFormalities',
      component: () =>
        import('@/views/Management/Home/LeaveManagement/Formalities/index.vue'),
      meta: {
        locale: '离职手续',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['*'],
        hideInMenu: false
      }
    },
    {
      path: 'myLeaveHandleWorkHandoverTask',
      name: 'MyLeaveHandleWorkHandoverTask',
      component: () =>
        import(
          '@/views/Management/Home/LeaveManagement/HandleWorkHandoverTask/index.vue'
        ),
      meta: {
        locale: '办理任务',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['*'],
        hideInMenu: false
      }
    },
    {
      path: 'myLeaveHandleWorkHandoverTaskDetail',
      name: 'MyLeaveHandleWorkHandoverTaskDetail',
      component: () =>
        import(
          '@/views/Management/Home/LeaveManagement/HandleWorkHandoverTask/Components/Detail/index.vue'
        ),
      meta: {
        locale: '离职工作交接详情（办理人）',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['*'],
        hideInMenu: true
      }
    },
    {
      path: 'myLeaveHandleTask',
      name: 'MyLeaveHandleTask',
      component: () =>
        import('@/views/Management/Home/LeaveManagement/HandleTask/index.vue'),
      meta: {
        locale: '接收任务',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['*'],
        hideInMenu: false
      }
    },
    {
      path: 'myHandlerLeaveFormalities',
      name: 'MyHandlerLeaveFormalities',
      component: () =>
        import(
          '@/views/Management/Home/LeaveManagement/HandleTask/Components/Formalities/index.vue'
        ),
      meta: {
        locale: '离职手续（办理人）',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['*'],
        hideInMenu: true
      }
    }
  ]
};

export default C_WAITDONE;
