/* 存放排班日历路由 */
import { DEFAULT_LAYOUT } from '../base';
// import DEFAULT_LAYOUT from '@/layout/default-layout.vue'
import { AppRouteRecordRaw } from '../types';

const C_SCHEDULINGCALENDAR: AppRouteRecordRaw = {
  path: 'schedulingCalendar',
  name: 'C_SchedulingCalendar',
  component: () => import('@/views/Client/SchedulingCalendar/index.vue'),
  meta: {
    locale: '排班日历',
    requiresAuth: true,
    icon: 'icon-home',
    order: 1,
    hideInMenu: false,
    roles: ['*']
  }
};

export default  C_SCHEDULINGCALENDAR;
