/* 审批相关路由 */

import { DEFAULT_LAYOUT } from '../base';
import { AppRouteRecordRaw } from '../types';
import homeSub from './homeSub';

const Approval: AppRouteRecordRaw = {
  path: '/approval/list',
  name: 'Approval',
  component: DEFAULT_LAYOUT,
  meta: {
    locale: '审批',
    requiresAuth: true,
    icon: 'icon-home',
    order: 10,
    hideInMenu: false,
    key: '10'
  },
  redirect: {
    name: 'ApprovalList'
  },
  children: [
    ...homeSub,
    {
      path: '/approval/list',
      name: 'ApprovalList',
      component: () => import('@/views/Management/Approval/FormList/index.vue'),
      meta: {
        locale: '表单管理',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['*'],
        hideInMenu: false
      }
    },
    {
      path: '/process/setting',
      name: 'FlowSetting',
      component: () => import('@/views/Management/Approval/Flow/index.vue'),
      meta: {
        locale: '审批流程设计',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['*'],
        hideInMenu: true
      }
    },
    {
      path: '/approval/condition/type',
      name: 'ConditionType',
      component: () =>
        import('@/views/Management/Approval/ConditionType/index.vue'),
      meta: {
        locale: '条件类型',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['*'],
        hideInMenu: false
      }
    },
    {
      path: '/app/approval/test',
      name: 'ConditionTypeTest',
      component: () => import('@/qiankun/index.vue'),
      meta: {
        locale: 'TEST',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['*'],
        hideInMenu: false
      }
    },
    {
      path: '/app/approval/applicationCenter',
      name: 'applicationCenter',
      component: () => import('@/qiankun/index.vue'),
      meta: {
        locale: '发起审批列表',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['*'],
        hideInMenu: true
      }
    },
    {
      path: '/approval/initiate',
      name: 'approvalInitiate',
      component: () =>
        import('@/views/Management/Approval/InitiateApproval/index.vue'),
      meta: {
        locale: '发起审批',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['*'],
        hideInMenu: true
      }
    },
    {
      path: '/app/approval/details',
      name: 'pppp',
      component: () => import('@/qiankun/index.vue'),
      meta: {
        locale: '审批详情',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['*'],
        hideInMenu: true
      }
    }
  ]
};

export default Approval;
